
    $small-padding: calc(var(--global-margin) * 0.3);
    $base-padding: calc(var(--global-margin) * 0.6);
    .base-fieldset {
        border: none;
        padding: 0;
        margin-bottom: calc(var(--global-margin) * 1.5);

        .content {
            padding: $base-padding;
            border: 1px solid var(--global-primary-background);
            background: var(--global-muted-background);
            height: 100%;
            align-self: stretch;


        }
        .fieldset-title {
            flex: 100%;
            padding:   $small-padding;
            background-color: var(--global-primary-background);
            color: var(--global-inverse-color);
        }
        h3 {
            font-size: calc(var(--global-font-size) * 1.5);
            margin-bottom: 0;
         //   color: var(--global-primary-background);
        }
    }
